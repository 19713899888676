import React from "react";
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import Seo from "../components/seo";
import useTeams from "../hooks/dataHooks/useTeams";

const Modules = loadable(() => import("../components/modules"));
const InnerIntroModule = loadable(() => import("../components/InnerIntroModule/InnerIntroModule"));
const PeopleCard = loadable(() => import("../components/PeopleCard/PeopleCard"));
// const StaticDetailPagination = loadable(() => import("../components/StaticDetailPagination/StaticDetailPagination"));

const IndexPage = (props) => {
  const teamData = useTeams();

  return (
    <Layout>
      <div className="layout-padding-top"></div>
        {/* <Modules {...props} /> */}
        <div className="inner-wrapper">
          <Container>
            <InnerIntroModule
                title={"Our People"}
            />

            <div className="inner-wrapper-card-main people-wrapper">
              {teamData.map((team) => {
                return (
                  <PeopleCard 
                    data={team}
                    page={props}
                    // img={PeopleImg_1}
                  />
                )
              })}

              {/* <StaticDetailPagination 
                  tag="people-wrapper"
              /> */}
            </div>
          </Container>
        </div>
    </Layout>
  )
}

export const Head = (props) => {
  const seo = props.pageContext.data.seo;
  return (
    <Seo title={seo?.title} description={seo?.metaDesc} />
  )
}
export default IndexPage